import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { getCollectionImages } from '../selectors';

import { updateOrderStatus } from '../actions/order';
import { createSelectImagePopup, createSelectCollectionImagesReminderPopup } from '../actions/popup';

import CollectionBadge from './CollectionBadge';

class CollectionPublish extends Component {

  constructor(props) {
    super(props);

    this.state = {
      copyText: 'Copy'
    };

    this.handleClickCopy = this.handleClickCopy.bind(this);
  }

  handleClickCopy(e) {
    const { collection } = this.props;
    navigator.clipboard.writeText(`${window.location.protocol}//${window.location.hostname}/collection/${collection.order_id}`);
    this.setState({ copyText: 'Copied' });
    if (this.copyTextTimeoutId) {
      clearTimeout(this.copyTextTimeoutId);
    }
    this.copyTextTimeoutId = setTimeout(() => {
      this.setState({ copyText: 'Copy' });
    }, 1000);
  }

  render() {
    const { collection, identity, images, onChangeImage, onChangeStatus, onCreateReminder } = this.props;
    const { copyText } = this.state;

    const hasCapability = capability => identity.capabilities.includes(capability);
    const locked = 1 == collection.locked || !hasCapability('MODIFY-COLLECTION');
    const hasAllImages = collection.collection_images.reduce((a, i) => a && !!i, true);

    return (
      <div className="main-content">
        <ul className="tabs" style={{ marginBottom: '1rem', borderTop: '0px', borderLeft: '0px', borderRight: '0px', borderBottom: '1px solid #edf2f5' }}>
          <li className="tabs-title">
            <NavLink to={`/collection/${collection.form_number}`} style={{ fontSize: '1.2rem' }} aria-selected={false}>Basics</NavLink>
          </li>
          <li className="tabs-title">
            <NavLink to={`/collection/${collection.form_number}/products`} style={{ fontSize: '1.2rem' }} aria-selected={false}>Products</NavLink>
          </li>
          <li className="tabs-title is-active">
            <a style={{ fontSize: '1.2rem' }} aria-selected={true}>Publish</a>
          </li>
        </ul>
        <div className="small-12 medium-4 medium-push-8 columns">
          <CollectionBadge collection={collection} onChangeImage={onChangeImage(images)} disabled={locked} />
        </div>
        {'Draft' === collection.status_name ?
        <div className="small-12 medium-8 medium-pull-4 columns">
          <div className="row collapse small-12 columns">
            {collection.client_id ?
              <h4>This collection will be available to {collection.client_name}!</h4> :
              <h4>This collection will be available to all commonsku distributors!</h4>}
          </div>
          <div className="row collapse small-12 columns">
            <a className="button large" style={{ marginRight: '1rem' }} target="_blank" href={`/collection/${collection.order_id}`}>Preview</a>
            <a className="button large" onClick={e => hasAllImages ? onChangeStatus('Published') : onCreateReminder(collection)}>Publish</a>
          </div>
        </div> : 'Published' === collection.status_name ?
        <div className="small-12 medium-8 medium-pull-4 columns">
          <div className="row collapse small-12 columns">
            {collection.client_id ?
            <h4>This collection is available to {collection.client_name}!</h4> :
            <h4>This collection is available to all commonsku distributors!</h4>}
          </div>
          <div className="row collapse small-12 columns">
            <p style={{ fontWeight: 'bold', fontSize: '0.9em', marginBottom: '0.25rem' }}>Copy this link to share it with commonsku distributors:</p>
            <div className="row collapse" style={{ backgroundColor: 'lightyellow', padding: '1rem', marginBottom: '1rem', borderRadius: '3px' }}>
              <div className="small-8 medium-10 columns">
                <input type="text" readOnly={true} style={{ display: 'inline-block', margin: '0 1rem 0 0' }} value={`${window.location.protocol}//${window.location.hostname}/collection/${collection.order_id}`} />
              </div>
              <div className="small-4 medium-2 columns" style={{ paddingRight: '1rem' }}>
                <button className="button expanded" style={{ margin: '0 0 0 1rem' }} onClick={this.handleClickCopy}>{copyText}</button>
              </div>
            </div>
          </div>
          <div className="row collapse small-12 columns">
            <a className="button large" style={{ marginRight: '1rem' }} target="_blank" href={`/collection/${collection.order_id}`}>Preview</a>
            <a className="button large secondary" onClick={e => this.props.onChangeStatus('Draft')}>Revert to Draft</a>
          </div>
        </div> :
        <div className="small-12 medium-8 medium-pull-4 columns">
          <p>This collection has been closed.</p>
        </div>}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  identity: state.identity,
  images: getCollectionImages(state, { order_id: ownProps.collection.order_id }),
  collection: Object.assign({}, ownProps.collection, {
    avatar: state.identity.company_avatar.small,
    title: ownProps.project.job_name,
  })
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onChangeImage: images => (selected_id, index) => () => dispatch(createSelectImagePopup(ownProps.collection.order_id, images, selected_id, index)),
  onChangeStatus: status_name => dispatch(updateOrderStatus(ownProps.collection.order_id, ownProps.collection.status_id, status_name)),
  onCreateReminder: collection => dispatch(createSelectCollectionImagesReminderPopup(collection))
});

export default connect(mapStateToProps, mapDispatchToProps)(CollectionPublish);
