import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';

import { createUpdateDisplayItemSummaryView } from '../actions/display';

import ItemList from './ItemList';
import { withRouter } from './helpers';

class CollectionProducts extends Component {

  render() {
    const { collection, location, product_list_style, onUpdateItemSummaryView } = this.props;
    return (
      <div className="main-content">
        <ul className="tabs" style={{ marginBottom: '1rem', borderTop: '0px', borderLeft: '0px', borderRight: '0px', borderBottom: '1px solid #edf2f5' }}>
          <li className="tabs-title">
            <NavLink to={`/collection/${collection.form_number}`} style={{ fontSize: '1.2rem' }} aria-selected={false}>Basics</NavLink>
          </li>
          <li className="tabs-title is-active">
            <a style={{ fontSize: '1.2rem' }} aria-selected={true}>Products</a>
          </li>
          <li className="tabs-title">
            <NavLink to={`/collection/${collection.form_number}/publish`} style={{ fontSize: '1.2rem' }} aria-selected={false}>Publish</NavLink>
          </li>
          <li>
            <div className="button-group compact-detailed-switch">
              <a onClick={e => { e.preventDefault(); onUpdateItemSummaryView(collection.order_id, 'collection-compact'); }} className={'button' + ('collection-compact' === product_list_style ? ' active' : '')}>Compact</a>
              <a onClick={e => { e.preventDefault(); onUpdateItemSummaryView(collection.order_id, 'collection-detailed'); }} className={'button' + ('collection-detailed' === product_list_style ? ' active' : '')}>Detailed</a>
            </div>
          </li>
        </ul>
        <ItemList
          key={collection.order_id}
          location={location}
          order_id={collection.order_id}
          order_type="COLLECTION"
          items={collection.items}
          display={product_list_style}
          hidden_filter_status="all"
          locked={1 == collection.locked}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const order_display = Object.assign({}, {
    item_summary_view: 'collection-detailed',
  }, state.display.orders[ownProps.collection.order_id]);
  return {
    location: ownProps.location,
    product_list_style: order_display.item_summary_view
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onUpdateItemSummaryView: (order_id, view_type) => dispatch(createUpdateDisplayItemSummaryView(order_id, view_type))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CollectionProducts));
