import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';

import { getOrderByNumber, getProject } from '../selectors';
import { getTenantOptions, getThemeDropdown, getUserOptions } from '../selectors/dropdowns';

import { updateOrder, createAddAccount, finalizeUpdateOrder } from '../actions/order';
import { updateProject, finalizeUpdateProject } from '../actions/project';
import { createAddCollectionTheme, createDeleteCollectionTheme } from '../actions/collection';

import CollectionThemes from './CollectionThemes';
import DateInput from './DateInput';
import Select from './Select';

class CollectionBasics extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    const { collection, identity, project, tenants, tenantMap, users, onChangeProject, onUpdateProject, onUpdateOrder, onUpdateAccount, onAddCollectionTheme, onDeleteCollectionTheme } = this.props;
    const hasCapability = capability => identity.capabilities.includes(capability);
    const locked = 1 == collection.locked || !hasCapability('MODIFY-COLLECTION');
    const onChangeProjectName = onChangeProject('job_name', project.job_name);
    const onUpdateProjectName = onUpdateProject('job_name', project.job_name);
    const onUpdateProjectRep = onUpdateProject('sales_rep_id', project.sales_rep_id);
    const onUpdateExpiryDate = onUpdateOrder('date_expiry');
    const onUpdateTenantAccount = tenant_id => {
      if (tenantMap[tenant_id].tenant_account_id) {
        return onUpdateOrder('client_company_id')(tenant_id);
      } else {
        return onUpdateAccount(tenant_id);
      }
    };

    return (
      <div className="main-content">
        <ul className="tabs" style={{ marginBottom: '1rem', borderTop: '0px', borderLeft: '0px', borderRight: '0px', borderBottom: '1px solid #edf2f5' }}>
          <li className="tabs-title is-active">
            <a style={{ fontSize: '1.2rem' }} aria-selected={true}>Basics</a>
          </li>
          <li className="tabs-title">
            <NavLink to={`/collection/${collection.form_number}/products`} style={{ fontSize: '1.2rem' }} aria-selected={false}>Products</NavLink>
          </li>
          <li className="tabs-title">
            <NavLink to={`/collection/${collection.form_number}/publish`} style={{ fontSize: '1.2rem' }} aria-selected={false}>Publish</NavLink>
          </li>
        </ul>

        <div className="row small-12 columns collapse">
          <div className="small-12 medium-2 columns">
            <strong>Title:</strong>
          </div>
          <div className="small-12 medium-4 columns end">
            <input name="job_name" type="text" value={project.job_name} placeholder="Be Creative!" onChange={e => onChangeProjectName(e.target.value)} onBlur={e => onUpdateProjectName(e.target.value)} disabled={locked} />
          </div>
        </div>
        <div className="row small-12 columns collapse">
          <div className="small-12 medium-2 columns">
            <strong>Themes:</strong>
          </div>
          <div className="small-12 medium-10 columns end">
              <CollectionThemes themes={this.props.themes} selected_themes={collection.collection_themes} onAddCollectionTheme={onAddCollectionTheme(collection.order_id)} onDeleteCollectionTheme={onDeleteCollectionTheme} disabled={locked} />
          </div>
        </div>
        {/*<div className="small-12 medium-2 columns">
          <strong>Recipient:</strong>
        </div>
        <div className="small-12 medium-10 columns">
          <Select value={collection.client_company_id} options={[{ key: '', value: 'Everyone on commonsku' }].concat(tenants)} change={onUpdateTenantAccount} placeholder="Everyone on commonsku" disabled={locked} />
        </div>*/}
        <div className="row small-12 columns collapse">
          <div className="small-12 medium-2 columns">
            <strong>Expires:</strong>
          </div>
          <div className="small-12 medium-4 columns end">
            <DateInput name="date_expiry" value={collection.date_expiry} onBlur={onUpdateExpiryDate} disabled={locked} />
          </div>
        </div>
        <div className="row small-12 columns collapse">
          <div className="small-12 medium-2 columns end">
            <strong>Author:</strong>
          </div>
          <div className="small-12 medium-4 columns end">
            <Select value={project.sales_rep_id} options={users} change={onUpdateProjectRep} disabled={locked} />
          </div>
        </div>
        <div className="row small-12 columns collapse">
          <div className="small-12 medium-2 columns">
          </div>
          {!locked ?
            <div className="small-12 medium-4 columns end">
              <NavLink to={`/collection/${collection.form_number}/products`} className="button large">Add Products</NavLink>
            </div> : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  identity: state.identity,
  project: getProject(state),
  themes: getThemeDropdown(state),
  users: getUserOptions(state),
  tenants: getTenantOptions(state),
  tenantMap: state.entities.tenants
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onUpdateProject: field => value => dispatch(updateProject(ownProps.project.job_id, field, ownProps.project[field], value)),
  onChangeProject: field => value => dispatch(finalizeUpdateProject(ownProps.project.job_id, field, ownProps.project[field], value)),
  onUpdateOrder: field => value => dispatch(updateOrder(ownProps.collection.order_id, field, ownProps.collection[field], value)),
  onUpdateAccount: value => {
    dispatch(finalizeUpdateOrder(ownProps.collection.order_id, 'client_company_id', ownProps.collection['client_company_id'], value));
    return dispatch(createAddAccount(value, 'DISTRIBUTOR')).then(
      action => dispatch(updateOrder(ownProps.collection.order_id, 'client_company_id', ownProps.collection['client_company_id'], action.payload.account.company_id))
  );},
  onAddCollectionTheme: order_id => theme_id => dispatch(createAddCollectionTheme(order_id, theme_id)),
  onDeleteCollectionTheme: collection_theme_id => dispatch(createDeleteCollectionTheme(collection_theme_id))
});
export default connect(mapStateToProps, mapDispatchToProps)(CollectionBasics);
