import { get } from 'lodash';
import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes, useLocation, useParams } from "react-router-dom";
import { connect } from 'react-redux';
import URI from 'urijs';

import { getProject } from '../selectors';
import { window, document } from '../global';
import CollectionApp from '../containers/CollectionApp';
import NoMatch from './NoMatch';
import ErrorBoundary from './ErrorBoundary';

const AppRoute = React.memo(({ panel, modal }) => {
  const params = useParams();
  const location = useLocation();

  if (panel || modal) {
    return <CollectionApp panel={panel} modal={modal} params={params} location={location} />;
  }

  return <NoMatch location={location} />;
});

const CollectionRouter = ({ collection_name, location }) => {

  useEffect(() => {
    document.title = `commonsku - Collection ${collection_name}`;
    // copy from old collection.tpl, looks like useless
    const url = window.url = new URI(window.location.href);
    window.keyword_sku = get(url.search(true), "keyword_sku");
    window.supplier_popup = get(url.search(true), "supplier_popup");
  }, [collection_name]);

  return (
    <BrowserRouter basename={location}>
      <Routes>
        <Route
          path="/collection/:order_number"
          element={<AppRoute panel="basics" />}
          ErrorBoundary={ErrorBoundary}
        />
        <Route
          path="/collection/:order_number/products"
          element={<AppRoute panel="products" />}
          ErrorBoundary={ErrorBoundary}
        />
        <Route
          path="/collection/:order_number/products/add-product"
          element={<AppRoute modal="search" />}
          ErrorBoundary={ErrorBoundary}
        />
        <Route
          path="/collection/:order_number/products/:item_id"
          element={<AppRoute modal="item" />}
          ErrorBoundary={ErrorBoundary}
        />
        <Route
          path="/collection/:order_number/publish"
          element={<AppRoute panel="publish" />}
          ErrorBoundary={ErrorBoundary}
        />
        <Route
          path={"*"}
          element={<AppRoute noMatch />}
          ErrorBoundary={ErrorBoundary}
        />
      </Routes>
    </BrowserRouter>
  );
};

const mapStateToProps = state => {
  const project = getProject(state);
  return {
    collection_name: project.job_name
  };
};

export default connect(mapStateToProps)(CollectionRouter);
