import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { createLoadOrder } from '../actions/order';
import { updateOrderStatus } from '../actions/order';
import { deleteCollection, copyCollection } from '../actions/project';
import { createValidateConfirmationPopup, createSpinnerPopup, closePopup } from '../actions/popup';

import { getOrderByNumber, getPopups, getProject } from '../selectors';

import Header from './Header';
import MainSection from './MainSection';

import Modal from '../components/Modal';
import Overlay from '../components/Overlay';
import DropdownMenu, { MenuTrigger } from '../components/DropdownMenu';
import CollectionBasics from '../components/CollectionBasics';
import CollectionProducts from '../components/CollectionProducts';
import CollectionPublish from '../components/CollectionPublish';
import PresentationItem from '../components/PresentationItem';
import ProductSearchModal from '../components/ProductSearchModal';
import ErrorBoundary from '../components/ErrorBoundary';
import { withRouter } from '../components/helpers';

class CollectionApp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      collection: props.collection,
      project: props.project,
    };
  }

  UNSAFE_componentWillMount() {
    if (!this.props.collection.loaded) {
      this.props.loadOrder(this.props.collection.order_id);
    }
  }

  componentDidUpdate(prevProps) {
    if(!_.isEqual(prevProps.params, this.props.params)
      || !_.isEqual(prevProps.location, this.props.location)
      || !_.isEqual(prevProps.project, this.props.project)
      || !_.isEqual(prevProps.collection, this.props.collection)
    ) {
      this.setState({
        project: this.props.project,
        collection: this.props.collection,
      });
    }
  }

  renderPanel(panel) {
    const { collection, project, location } = this.state;

    switch (panel) {
      case 'basics':
        return <CollectionBasics collection={collection} project={project} />;
      case 'products':
        return <CollectionProducts collection={collection} project={project} location={location} />;
      case 'publish':
        return <CollectionPublish collection={collection} project={project} />;
      default:
        return null;
    }
  }

  renderModal(modal) {
    const { params } = this.props;

    switch (modal) {
      case 'search':
        return <ProductSearchModal params={params} isCollectionPage={true} />;
      case 'item':
        return <PresentationItem params={params} />;
    }
  }

  render() {
    const { identity, modal, panel, popups, onChangeStatus, onCopyCollection, onDeleteCollection } = this.props;
    const { collection, project } = this.state;

    if (!collection.loaded) {
      return (
        <div className="main-content" style={{ textAlign: 'center' }}>
          <img style={{ marginTop: '2rem' }} src="/images/gears.gif" />
        </div>
      );
    }

    if (modal) {
      return (
        <DndProvider backend={HTML5Backend}>
          <ErrorBoundary>
            <div>
              <Modal modal={this.renderModal(modal)} project={project} order={collection} popups={popups} />
              <Overlay popups={popups} />
            </div>
          </ErrorBoundary>
        </DndProvider>
      );
    }

    const hasCapability = capability => identity.capabilities.includes(capability);
    const locked = 1 == collection.locked || !hasCapability('MODIFY-COLLECTION');
    const has_copy = hasCapability('CREATE-COLLECTION');
    const has_delete = hasCapability('DELETE-COLLECTION');

    const handleCopyCollection = e => {
      e.preventDefault();
      if (!has_copy) {
        return;
      }
      onCopyCollection(collection.order_id, project.job_name);
    };

    const handleDeleteCollection = e => {
      e.preventDefault();
      if (!has_delete) {
        return;
      }
      onDeleteCollection(collection.order_id);
    };

    const actionOptions = [
      {
        value: <a href={`/collection/${collection.order_id}`} target="_blank">Preview</a>,
        hideOnClick: true,
        show: collection.status_name !== 'Closed'
      }, {
        value: 'Close Collection',
        hideOnClick: true,
        onClick: () => onChangeStatus('Closed', collection.order_id, collection.status_id),
        show: hasCapability('MODIFY-COLLECTION') && collection.status_name !== 'Closed'
      }, {
        value: 'Revert to Draft',
        hideOnClick: true,
        onClick: () => onChangeStatus('Draft', collection.order_id, collection.status_id),
        show: hasCapability('MODIFY-COLLECTION')  && collection.status_name === 'Closed'
      }, {
        value: 'Copy Collection',
        hideOnClick: true,
        onClick: handleCopyCollection,
        show: has_copy
      }, {
        value: 'Delete Collection',
        className: 'alert',
        onClick: handleDeleteCollection,
        hideOnClick: true,
        show: has_delete
      }
    ].filter(a => a.show);

    return (
      <DndProvider backend={HTML5Backend}>
        <ErrorBoundary>
          <Header>
            <div className="columns">
              <DropdownMenu className="actions-dropdown" style={{ float: 'right', marginRight: '1rem' }} options={actionOptions} align="right">
                <MenuTrigger>
                  <a className="button actions-dropdown-button alt bold trigger-button" style={{ marginBottom: '0.5rem' }}>Actions</a>
                </MenuTrigger>
              </DropdownMenu>
              <h4>Collection #{collection.form_number} &mdash; {project.job_name} ({collection.status_name})</h4>
            </div>
          </Header>
          <MainSection collection={collection} project={project} popups={popups}>
            {this.renderPanel(panel)}
          </MainSection>
          <Overlay popups={popups} />
        </ErrorBoundary>
      </DndProvider>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  identity: state.identity,
  project: getProject(state),
  collection: getOrderByNumber(state, { form_number: ownProps.params.order_number, order_type: 'COLLECTION' }),
  popups: getPopups(state)
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  loadOrder: order_id => dispatch(createLoadOrder(order_id)),
  onChangeStatus: (status_name, order_id, status_id) => dispatch(updateOrderStatus(order_id, status_id, status_name)),
  onDeleteCollection: order_id => dispatch(createValidateConfirmationPopup(() => dispatch(deleteCollection(order_id)), [])),
  onCopyCollection: (order_id, job_name) => {
    dispatch(createSpinnerPopup('Copying Collection...'));
    return dispatch(copyCollection(order_id, job_name)).then(
      () => {},
      () => dispatch(closePopup()),
    );
  }
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CollectionApp));
